import React from 'react';

import Oberfläche from '../../resources/Anleitung_icons/interface.svg';
import searching from '../../resources/Anleitung_icons/searching.svg';
import services from '../../resources/Anleitung_icons/services.svg';
import sharing from '../../resources/Anleitung_icons/sharing.svg';

import abstand from '../../resources/Anleitung_icons/abstand.png';
import coil_stapel from '../../resources/Anleitung_icons/coil_stapel.png';
import coil_wickel from '../../resources/Anleitung_icons/coil_wickel.png';
import rundzelle from '../../resources/Anleitung_icons/rundzelle.png';
import sheet_stapel from '../../resources/Anleitung_icons/sheet_stapel.png';
import sheet_wickel from '../../resources/Anleitung_icons/sheet_wickel.png';
import stapel_hardcase from '../../resources/Anleitung_icons/stapel_hardcase.png';
import stapel_pouch from '../../resources/Anleitung_icons/stapel_pouch.png';
import whe from '../../resources/Anleitung_icons/whe.png';
import wickel_hardcase from '../../resources/Anleitung_icons/wickel_hardcase.png';


export default function Anleitung() {
  function Spacer() {
    return <div style={{ height: 50 }} />;
  }

  function Img(props) {
    return (
      <img
        src={props.source}
        alt={props.alt}
        style={{ height: 200, marginTop: 35 }}
      />
    );
  }

  const Legende = [
    { id: 1, Beschreibung: 'Electrode length', Einheit: 'mm' },
    { id: 2, Beschreibung: 'Electrode width', Einheit: 'mm' },
    { id: 3, Beschreibung: 'Current collector width', Einheit: 'mm' },
    { id: 4, Beschreibung: 'Width of hardcase', Einheit: 'mm' },
    { id: 5, Beschreibung: 'Length of hardcase', Einheit: 'mm' },

    { id: 6, Beschreibung: 'Height of hardcase', Einheit: 'mm' },

    { id: 7, Beschreibung: 'Distance roll to cap', Einheit: 'mm' },
    { id: 8, Beschreibung: 'Distance tap to case', Einheit: 'mm' },
    { id: 9, Beschreibung: 'Radius of winding core', Einheit: 'mm' },
    { id: 10, Beschreibung: 'Width of the winding core', Einheit: 'mm' },
    { id: 11, Beschreibung: 'Additional layers of separator (in-/outside)', Einheit: 'mm' },
    { id: 12, Beschreibung: 'Height of the cylindrical cell', Einheit: 'mm' },
    { id: 13, Beschreibung: 'Radius of the cylindrical cell ', Einheit: 'mm' },
    {
      id: 14,
      Beschreibung: 'Wall thickness of the cell case',
      Einheit: 'mm',
    },

  ];

  return (
    <>
      <div className="bx--col-lg-20">
        <h3>User manual</h3>
        <p className="anleitungstext">
          The program enables an economic consideration of a lithium-ion battery production on a large scale.
          The program guides the user through a step-by step approach, in order to set the battery configuration,
          the design of the production line, the specification of important parameters,
          also including a visual representation of the results.
          In the following, each step will be explained in detail.
          <br />
          <strong>Note:</strong> The program was developed as part of the project eKoZell,
          funded by the German Federal Ministry of Education and Research.
        </p>
        <br />
        <h3>Cell design</h3>
        <p className="anleitungstext">
          The cell is modeled under the tab{' '}
          <strong>Cell design</strong>. The modeling is roughly divided into external parameters
          (cell type, dimensions of the cell, size of production) on the left
          and the cell chemistry and electrode configuration on the right side.
          <br />
          <br /> First, the cell format can be selected. There are two cell formats to choose from, either with cylindrical or prismatic cell case. 
          For the prismatic cell case, a flat winding core is considered. The predefined dimensions of the cells can then be customized by the user. 
          An overview of the various cell design parameters is shown in the figures below. 
          After the cell dimensions are determined, the annual production capacity in terms of GWh can be specified.<br />
          <br />
          On the right-hand side, one of the predefined cell chemistry can be selected.
          It describes which materials are used to what extent in the cell.
          However, materials can be removed from the cell via the red minus symbol
          and new materials can be added from the database via the dropdown windows.
          The quantities must add up to 100%. Additional parameter specifications
          (e.g. thickness of the separator or specific capacity of the active material)
          for the chosen materials can be selected under {' '}
          <strong>Material details</strong>. All other missing parameters
          that cannot be assigned to either the cell dimensions or the materials
          (e.g. cell voltage) are set on the right side under  <strong>Other parameters</strong>. <br />
          <br /> After setting all the cell design parameters,
          the button 'Results' is activated in the upper right corner.
          By pressing, the cell is calculated and the program directly jumps to the results.
          All results are recorded in table form and also, donut diagrams are used to show the quantity
          and cost distribution within a cell. The cost distribution of an individual cell differs from the distribution of the materials used for the annual production,
          since different losses occur for different materials (e.g. the suspension is subject to all losses,
          since it is added early in the production, the electrolyte to fewer losses, since it is added later).
        </p>
        <br />

        <div className="bx--grid">
          <div className="bx--row">

            <div className="bx--col-lg-6">
              <h3>Single Sheet Dimension</h3>
              <br />

              <div className="bx--row">


                <div className="bx--col-lg-6">
                  <img
                    alt="sheet_wickel"
                    src={sheet_wickel}
                    style={{ height: 180 }}
                  />
                </div>

                <div className="bx--col-lg-16">
                  <br />
                  <h3>Legend</h3>
                  <br />
                  <ol>
                    {Legende.map(item => (
                      <li key={item.id}>
                        {item.id}. {item.Beschreibung} [{item.Einheit}]
                      </li>
                    ))}
                  </ol>
                </div>

              </div>
            </div>


            <div className="bx--col-lg-2">

            </div>


            <div className="bx--col-lg-8">
              <h3>Cell Format Dimensions</h3>
              <br />
              <div className="bx--row">
                <div className="bx--col-lg-9" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div>
                    <figcaption>
                      <h4>Prismatic Cell</h4>
                    </figcaption>
                  </div>
                  <img
                    alt="wickel_hardcase"
                    src={wickel_hardcase}
                    style={{ height: 350 }}
                  />
                </div>

                <div className="bx--col-lg-1">

                </div>

                <div className="bx--col-lg-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <figcaption>
                    <h4>Cylindrical Cell</h4>
                  </figcaption>
                  <img
                    alt="rundzelle"
                    src={rundzelle}
                    style={{ height: 350 }}
                  />
                </div>
              </div>
            </div>


            <br />
            <div className="bx--col-lg-6">
            <br />
            <br />
            <br />
              <figcaption>
                <h3>Coating layout</h3>
                <br />
              </figcaption>
              <img
                alt="coil_wickel"
                src={coil_wickel}
                style={{ height: 250 }}
              />
            </div>

          </div>
        </div>


        <br />
        <h3>Process design</h3>
        <p className="anleitungstext">
          The process chain is defined under the tab {' '}
          <strong>Process design</strong>. On the left, the chosen process chain is shown,
          distinguished by the different sections of the production.
          Selected process steps can be removed via the red minus, and also,
          process steps can be added to the chain  with the blue plus.
          All available process steps are shown in the middle. However, duplicates are not possible.
          Alternatively, a predefined process chain can be selected from the drop-down menu at the top right of the window.
          Once a process step has been added, it appears on the right-hand side and
          can be expanded to allow the customization of the individual process parameters.
          These include production parameters such as throughput, power consumption, or required space.
        </p>
        <br />
        <h3>General parameters</h3>
        <p className="anleitungstext" >
          Further parameters that are neither assigned to the cell, nor to the process steps,
          are set under the tab {' '}
          <strong>General paramters</strong>. These are further divided into four sections:
          <ul style={{ listStyleType: 'square', marginLeft: '30px' }}>
            <li>
              <strong>Economic parameters: </strong>General economic
              parameters
            </li>
            <li>
              <strong>Employees & logistics: </strong>Parameters related to employees and logistics,
              such as working hours or employee costs.
            </li>
            <li>
              <strong>Building: </strong>Area costs and surcharges for additional areas
            </li>
            <li>
              <strong>Recovery: </strong>Percentage of material that can be recovered from the lost material through production
            </li>
          </ul>
        </p>
        <br />
        <h3>Results</h3>
        <p className="anleitungstext">
          After cell design and process design are completed, a button labeled 'Results' appears
          under the tab <strong>Results</strong>. It starts the calculation and display of the results.
          {' '}
        </p>
        <br />
      </div>



    </>
  );
}
