const route = [{
    "Material formulation":[]
},{
    "Layer fabrication": []
},{
    "Electrode conditioning": []
},{
    "Electrode assembly": []
},{
    "Cell assembly": []
},{
    "Electrolyte filling": []
},{    
    "Formation": []
}]

const prozessRouteReducer = (state = route, action) => {
    switch (action.type) {
      case 'PROZESSROUTE_CHANGE':
        return action.payload;
      default:
        return state;
    }
  };
  export default prozessRouteReducer;
  
