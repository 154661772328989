import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tabs,
  Tab
} from 'carbon-components-react';
import { InfoSection, InfoCard } from '../../components/Info';
import Anleitung from './Anleitung';
import { useSelector } from "react-redux";
import DownloadButton from '../Ergebnisse/components/DownloadButton';
import { BatteryCharging32, ChartNetwork32, Education32 } from '@carbon/icons-react';

const props = {
  tabs: {
    selected: 0,
    role: 'navigation',
  },
  tab: {
    role: 'presentation',
    tabIndex: 0,
  },
};


const LandingPage = () => {
  const [currentTime, setCurrentTime] = useState(0);
  const [chosenTab, setChosenTab] = useState(0)
  const quellen = useSelector(state => state.quellen)

  useEffect(() => {
    fetch('/time')
      .then(res => res.json())
      .then(data => {
        setCurrentTime(data.time);
      });
  }, []);

  const Unterstützer = [
    "Max Mitrenga",
    "Patrick Berghäuser",
    "Anna Kollenda",
    "Lukas Kemmer",
    "Maximilian Blaschke",
    "Josef Keilhofer",
    "Maximilian Lechner",
    "Julian Mayer",
    "Konrad Bendzuck"
  ]

  const Versionskontrolle = [
    {
      Version: "V1.0 - Release",
      Datum: "01.12.2022",
      Änderungen: ["Paper Publication"]
    }
    /*
    ,
    {
      Version: "V1.1",
      Datum: "28.03.2022",
      Änderungen: [
        "Fehlerbehebun in der Zellauslegung",
        "Hinzufügen einer Anleitung"
      ]
    },
    {
      Version: "V1.2",
      Datum: "04.04.2022",
      Änderungen: [
        "Umbenennung der Zelltypen",
        "Hinzufügen von NCM 811/Graphit/Si Zellchemien",
        "PLatzhalter Prozessschritt 'Benetzen' hinzugefügt"
      ]
    },
    {
      Version: "V1.3",
      Datum: "05.04.2022",
      Änderungen:
        [
          "Fehlerbehebung in der Hardcase Wickelzelle"
        ]
    },
    {
      Version: "V1.4",
      Datum: "06.04.2022",
      Änderungen: [
        "Anzeige Trockenraum in der Ergebnisdarstellung korregiert",
        "Platzhalter für weitere Graphen in dern Ergebnissen eingefügt"
      ]
    }
    */
  ]


  return (
    <div className="bx--grid bx--grid--full-width landing-page">
      <div className="bx--row __banner">
        <div className="bx--col-lg-16">
          <Breadcrumb noTrailingSlash aria-label="Page navigation">
            <BreadcrumbItem>
              <a href="/">Home Page</a>
            </BreadcrumbItem>
          </Breadcrumb>
          <h1 className="__heading">ProZell Cost Model</h1>
        </div>
      </div>
      <div className="bx--row landing-page__r2">
        <div className="bx--col bx--no-gutter">
          <Tabs {...props.tabs} aria-label="Tab navigation" selected={chosenTab} >
            <Tab {...props.tab} label="Overview" onClick={() => setChosenTab(0)}>
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                <div className="bx--row landing-page__tab-content">
                  <div className="bx--col-md-4 bx--col-lg-7">
                    <h2 className="landing-page__subheading">
                      What is the ProZell cost model?
                    </h2>
                    <p>Last visit: {currentTime}</p>
                    <p className="landing-page__p">
                      The ProZell cost model uses a flexible implementation
                      for pricing individual process steps of a battery cell production,
                      as well as for an economic evaluation of new process steps and
                      further optimization of the process route.
                    </p>




                    <Button onClick={() => setChosenTab(1)}>User manual</Button>

                  </div>
                  <div className="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
                    <img
                      className="landing-page__illo"
                      src={`${process.env.PUBLIC_URL}/batteries5.png`}
                      alt="Carbon illustration"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab {...props.tab} label="User manual" onClick={() => setChosenTab(1)}>
              <div className="bx--grid" id="Anleitung" >
                <div className="bx--row  landing-page__tab-content">

                  <Anleitung />

                </div>
              </div>
            </Tab>
            <Tab {...props.tab} label="Imprint" onClick={() => setChosenTab(2)}>
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                <div className="bx--row landing-page__tab-content">
                  <div className="bx--col-lg-12">
                    {Versionskontrolle.map((item, index) =>
                      <div key={index}>
                        <p>{item.Version} ({item.Datum})</p>
                        {item.Änderungen !== [] &&
                          <ul style={{ listStyle: "inherit", paddingLeft: 40 }}>
                            {item.Änderungen.map((item, index) =>
                              <li key={index}>{item}</li>
                            )}
                          </ul>
                        }
                      </div>
                    )}

                  </div>
                  <div className="bx--col-lg-4">
                    <p>
                      <span style={{ fontWeight: "bold" }}>Contact:</span><br /> Kashfia Mahin <br /> kashfia.mahin@tu-braunschweig.de
                    </p>
                    <br />
                    <p><span style={{ fontWeight: "bold" }}>Support by:</span></p>
                    {Unterstützer.map(item => <p key={item}>{item}</p>)}

                  </div>
                </div>
              </div>
            </Tab>
            <Tab {...props.tab} label="Sources" onClick={() => setChosenTab(3)}>
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                <div className="bx--row landing-page__tab-content">
                  <div className="bx--col-lg-16">
                    <div style={{
                      display: "flex",
                      flexDirection: "row",

                    }}>
                      <h3>Sources</h3>
                      <DownloadButton name="Sources" data={quellen} />
                    </div>
                    
                    <table style={{ borderSpacing: "10px", borderCollapse: "separate" }}>
                      <tbody>
                        {quellen !== null && JSON.parse(quellen).map(item =>
                          <tr key={item.id}>
                            <td>[{item.Reference_number}]</td>
                            <td>      </td>
                            <td>{item.Name !== null && " " + item.Name} {item.Titel} {item.Verlag} ({item.Jahr}). {item.doi !== null && " " + item.doi} {item.ISBN !== null && " " + item.ISBN} {item.Website_address !== null && " " + item.Website_address} {item.Accessed_date !== null && " " + item.Accessed_date}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <InfoSection heading="Institute" className="landing-page__r3">
        <InfoCard
          heading="Kompetenzcluster ProZell"
          body={
            <><span style={{ fontSize: "10pt" }}>The aim of the competence cluster for battery cell production is to research and improve the production process of battery cells and its influence on cell properties and product development costs.</span><br />
              <a rel="noreferrer" href="https://prozell-cluster.de/" target="_blank" style={{ color: "black" }}>ProZell Cluster</a></>
          }
          icon={<ChartNetwork32 />}
        />
        <InfoCard
          heading="Institute for Particle Technology iPAT"
          body={
            <><span style={{ fontSize: "10pt" }}>We are interested in topics of basic research and teaching as well as in a wide variety of application fields of particular systems – with a focus on mobility, life sciences and materials.</span><br />
              <a rel="noreferrer" href="https://www.tu-braunschweig.de/ipat" target="_blank" style={{ color: "black" }}>iPAT</a></>
          }
          icon={<Education32 />}
        />
        <InfoCard
          heading="Battery LabFactory Braunschweig BLB"
          body={
            <><span style={{ fontSize: "10pt" }}>The Battery LabFactory Braunschweig (BLB) is a research center of the TU Braunschweig. The research spectrum covers the entire value cycle from material and electrode production to cell production to system integration and recycling to close the material cycle.</span><br />
              <a rel="noreferrer" href="https://www.tu-braunschweig.de/blb" target="_blank" style={{ color: "black" }}>BLB</a></>
          }
          icon={<BatteryCharging32 />}
        />
      </InfoSection>
    </div>
  );
};

export default LandingPage;
